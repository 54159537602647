.sweet-heading h3 {
  font-size: 16px;
  font-weight: 600;
}
.accountState {
  font-size: 14px;
}
.stateMessage {
  font-size: 13px;
}
.firstcard-div ul {
  padding: 0px !important;
}
.sweetAlertUl {
  margin-top: 2%;
}
.sweetAlertUl li {
  list-style: none;
}
.sweet-popup {
  display: flex;
  width: 100%;
  height: 100%;
}
.sweet-popup h5 {
  font-size: 15px;
  font-weight: 600;
  margin-left: 1px;
  margin-top: 2.5px;
}
.sweetAlertLi {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  padding-bottom: 4px;
}
.sweetAlertLiExpand {
  background: #f0f2f5;
}
.li-wrap {
  margin-top: 9px;
}
.li-wrap:hover {
  background: #f0f2f5;
}
input[type="radio"] {
  cursor: pointer;
}
.form-control {
  border: 1px solid #e7e7e8 !important;
}
.payment-wrap {
  margin-top: 3%;
  margin-bottom: 5%;
}
.required {
  color: red;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.payment_form_logo {
  display: block;
  width: 150px;
}
.payment_form_logo_mbl {
  display: none;
}
/* .main_stepper_form .makeStyles-paper-4 {
  margin-top: 0px !important;
} */
.cal-price-div {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: flex-end; */
  /* padding-right: 12px; */
  margin-bottom: 4px;
}
.cal-price-div > h4 {
  font-size: 13px;
  font-weight: 600;
  /* margin-right: 6px; */
  margin-bottom: 0px;
  white-space: nowrap;
}
.cal-price-div span {
  font-size: 13px;
}
.auto-complete-inputs > div > div > div > button:first-child {
  display: none;
}
@media (min-width: 576px) {
  .cal-price-div > h4 {
    text-align: right;
  }
}
@media (max-width: 400px) {
  .payment_form_logo {
    display: none;
  }
  .payment_form_logo_mbl {
    display: block;
    width: 150px;
    margin: 0 auto;
  }
}
