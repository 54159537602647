.subtotal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* width: 300px; */
  /* height: 100px; */
  padding: 20px;
  background-color: white;
  border: 1px solid #dddddd;
  border-radius: 3px;
}

.subtotal__gift {
  display: flex;
  align-items: center;
}

.subtotal__gift > input {
  margin-right: 5px;
}

.subtotal > button {
  background: #3eb986 !important;
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
}
@media (max-width: 992px) {
  .subtotal {
    text-align: center;
    margin-top: 2%;
  }
  .subtotal > button {
    align-self: center;
    width: 33%;
  }
}
@media (max-width: 767px) {
  .subtotal {
    margin-top: 2%;
  }
  .subtotal > button {
    align-self: center;
    width: 40%;
  }
}
@media (max-width: 650px) {
  .subtotal {
    margin-top: 3%;
  }
  .subtotal > button {
    align-self: center;
    width: 50%;
  }
}
@media (max-width: 500px) {
  .subtotal {
    margin-top: 4%;
  }
  .subtotal > button {
    align-self: center;
    width: 70%;
  }
}
@media (max-width: 425px) {
  .subtotal > button {
    align-self: center;
    width: 88%;
  }
}
@media (max-width: 360px) {
  .subtotal > button {
    align-self: center;
    width: 100%;
  }
}
