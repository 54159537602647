.my_orders {
  margin-top: 20px;
  box-shadow: 0px 0px 8px #dbdbdb !important;
  border-radius: 4px !important;
}
.my_orders th {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  white-space: nowrap;
}
.no_orders {
  position: absolute;
  margin: 1.7% auto;
  left: 43%;
}
/* .my_orders .MuiTableCell-head {
  color: #161515de !important;
  font-weight: 600 !important;
  line-height: 1.7rem !important;
}
.my_orders .MuiTableCell-sizeSmall {
  padding: 16px !important;
}
.my_orders .main_rows {
  background-color: #f8f7f7 !important;
} */
.order_return_button {
  width: 142px;
  font-size: 14px !important;
  letter-spacing: 0.6px;
}
.return_button {
  background-color: #64bd6b !important;
  border-color: #64bd6b !important;
  padding: 3px 8px !important;
  margin-top: 5px;
}
.return_button:focus {
  box-shadow: 0 0 0 2px #c37d25 !important;
}
/* .modal-open {
  padding-right: 0px !important;
} */
.return_request_heading {
  font-size: 1.5rem !important;
  /* text-align: center; */
}
.return_request_r {
  padding: 20px;
  padding-top: 32px;
}
.return_request .MuiFormControl-root {
  min-width: 300px;
  border: 1px solid #c0c0c0;
  border-radius: 6px;
  width: 100%;
  min-height: 36px;
}
.MuiSelect-icon {
  top: calc(50% - 12px) !important;
}
.return_request .MuiSelect-select.MuiSelect-select {
  padding-left: 16px;
}
.return_request .form-control {
  border: 1px solid #c0c0c0 !important ;
}
.create-request {
  box-shadow: 0 0 8px #dbdbdb !important;
  padding: 22px 36px 28px;
  background-color: #fff;
  margin-top: 20px;
  border-radius: 6px;
}
/* .modal-content {
  padding: 16px;
} */
.awaiting {
  background: #fcfc12;
}
.approved {
  background: #27e827;
}
.rejected {
  background: #ff3f3f;
}
.withdrawal {
  background: #589afe;
}
/* .withdrawal, */
.awaiting {
  height: 32px;
  padding: 4px 8px;
}
.rejected,
.approved,
.withdrawal {
  height: 30px;
  padding: 4px 0px;
  width: 100px;
  width: 100px;
}
.awaiting,
.approved,
.rejected,
.withdrawal {
  border-radius: 13px;
  /* color: #fff; */
  text-align: center;
}
.collapse-rows tr:last-child > td {
  border-bottom: none !important;
}
