html,
body {
  max-width: 100%;
  overflow-x: hidden;
}
.home {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;
}

.home-banner-main-grid {
  background-image: url(../../assets/banners/mainbanner.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100% !important;
  height: 350px !important;
  /* padding: 50px !important; */
  background-position: center !important;
}

.banner-text-main-div {
  width: 100% !important;
  height: 100% !important;
  /* background-color: red !important; */
  text-align: center !important;
  align-items: center !important;
  display: flex !important;
  padding-left: 250px !important;
}

@media (max-width: 920px) {
  .banner-text-main-div {
    padding: 0px !important;
    justify-content: center !important;
    display: flex !important;
  }
}

@media (max-width: 490px) {
  .banner-text-1 {
    font-size: 30px !important;
  }

  .banner-text-2 {
    font-size: 15px !important;
  }
}

.banner-text-1 {
  font-size: 50px !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
}

.banner-text-2 {
  font-size: 20px !important;
  color: #444 !important;
}

.home-banner-main-grid-2 {
  background-image: url(../../assets/banners/sofabanner.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100% !important;
  height: 350px !important;
  background-position: center !important;
}

.home-banner-main-grid-3 {
  background-image: url(../../assets/banners/spada-trader-banner-3.jpg) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100% !important;
  height: 350px !important;
  background-position: center !important;
}

.container-max-width {
  max-width: 1440px !important;
  padding: 0px !important;
}

/* ......................................slider btns ...................................... */

.slick-prev:before {
  color: #3eb986 !important;
  opacity: 1 !important ;
}

.slick-prev.slick-disabled:before {
  color: #3eb986 !important;
  opacity: 1 !important;
}

.slick-next.slick-disabled:before {
  color: #3eb986 !important;
  opacity: 1 !important;
}

.slick-next:before {
  color: #3eb986 !important;
  opacity: 1 !important ;
}

/* ......................................slider btns ...................................... */

.home__row {
  display: flex;
  z-index: 1;
  margin-left: 5px;
  margin-right: 5px;
}

.home__image {
  width: 100%;
  /* z-index: 1; */
  /* margin-bottom: -215px; */
  margin-bottom: -18%;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
.shop-card-home {
  margin-bottom: 20px !important;
}
.shop-card-home .MuiTypography-gutterBottom {
  color: black !important;
  font-size: 12px;
}
a {
  text-decoration: none !important;
  color: #007185 !important;
}
.MuiTypography-h5 {
  font-size: 1.3rem !important;
  font-weight: 600 !important;
}

.shop-card-home .MuiCardContent-root {
  padding-bottom: 0px !important;
  background: #fff !important;
}
.inner-shop-card-home .MuiCardContent-root {
  /* padding-right: 2% !important;
  padding-left: 2% !important; */
  padding: 1% 2% !important;
}
.slider-div {
  background-color: #fff;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.MuiPaper-rounded {
  border-radius: 0px !important;
}
.category-buttons .MuiButton-root {
  text-transform: none !important;
}

.slider-heading-div {
  display: flex;
}
.slider-heading {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  margin-right: 1%;
  margin-left: 2%;
  color: #444 !important;
  font-weight: 500;
}
.slider-shopnow-link {
  margin-top: 0.35%;
  font-size: 13px;
  text-decoration: none !important;
  font-weight: 500;
}
.slider-image {
  cursor: pointer;
  width: auto;
  /* height: auto; */
  min-width: 60%;
  max-width: 100%;
  /* max-height: 200px; */
  min-height: 100px;
  max-height: 190px;
  vertical-align: middle;
  z-index: 100;
  /* margin-left: -8%; */
  padding: 0px 6px;
}
.slider-div {
  text-align: center;
  position: relative;
  display: inline-block;
  min-width: 145px;
  /* width: 250px; */
}

.slider-div2 .slick-prev {
  left: -10px;
  z-index: 1000;
}
.slider-div2 .slick-next {
  right: -10px;
  z-index: 1000;
}
.slider-div2 .slick-prev:before,
.slider-div2 .slick-next:before {
  color: black !important;
  /* z-index: 1000; */
}
.slider-div2 .slick-track:first-child {
  margin-left: 2%;
}
.slider-div2 {
  max-height: 280px;
  min-height: 180px;
  background-color: #fff !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  padding: 25px 15px !important;
}
.slider-div2 .slick-track {
  display: flex;
  align-items: center;
}
/* code to unset fix width of slider images*/
/* .slider-div2 div div .slick-slide {
  width: fit-content !important;
} */

/* Responsive settings start here */

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (max-width: 991px) {
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (max-width: 767px) {
  .home {
    overflow: hidden;
    padding-bottom: 20px;
  }
  .home__image {
    margin-bottom: -50px;
  }
  .home__row {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
}

@media (max-width: 479px) {
}

/* Responsive settings end here */

.feature-card-product-main {
  width: 100% !important;
  height: auto !important;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  border-radius: 10px !important;
}

.featured-products-text {
  font-size: 30px !important;
  color: black !important;
  font-weight: bold !important;
  z-index: 10000000 !important;
  padding-top: 20px !important;
  text-align: center !important;
}
