.navbar {
  /* background-color: #131921; */
  /* height: 80px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 0;
  padding: 0.25rem 0rem 0.5rem 1rem !important;
}

.menu-bars {
  /* margin-left: 2rem; */
  font-size: 1.5rem;
  background: none;
}

.nav-menu {
  background-color: white;
  /* width: 350px; */
  width: 27%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  z-index: 100;
}
.nav-menu.active ul {
  padding-inline-start: 0px !important;
}
.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}
.nav-text span {
  padding-left: 10px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  /* background-color: #131921; */
  background-color: #232f3e;
  width: 100%;
  height: 60px;
  height: 55px;
  display: flex;
  /* justify-content: end; */
  justify-content: flex-end;
  margin-right: 1rem;
  padding-right: 8px;
  padding-top: 4px;
  /* margin-top: 1rem; */
  /* align-items: center; */
}
.list-category {
  padding: 6px 12px 6px 26px;
  border-bottom: 1px solid #cdcccc;
  display: flex;
  justify-content: space-between;
}
.list-category svg {
  font-size: 16px;
}
/* span {
  margin-left: 16px;
} */
@media (max-width: 767px) {
  .fa_bars {
    color: #fff;
    font-size: 1.25rem;
    margin-top: 10px;
  }
}
