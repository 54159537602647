.invoice-details {
  margin-top: 20px;
  font-size: 13px;
  color: #332e38;
  box-shadow: 0px 0px 8px #dbdbdb !important;
  border-radius: 4px !important;
}
.invoice-details-table thead {
  background-color: #dee2e6 !important;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  white-space: nowrap;
}
.invoice-details-table td {
  vertical-align: middle !important;
}
.invoice-details-table tr:last-child > td {
  border-bottom: 1px solid #dee2e6;
}
.fs-14 {
  font-size: 14px !important;
}
@media print {
  body * {
    visibility: hidden;
    position: relative;
  }
  #print-area {
    position: absolute;
    left: -30%;
    top: 0;
  }
  #print-area,
  #print-area * {
    visibility: visible;
    height: auto;
    width: 100vw;
    margin: auto;
  }
}
