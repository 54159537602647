/* .wishlist-heading {
  font-size: 22px;
  margin-top: 34px;
  /* padding-left: 1%; */
/* } */
.no_product_wishlist {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  margin-top: 5%;
  margin-bottom: 5%;
}
@media (max-width: 767px) {
  .wishlist-heading {
    font-size: 18px;
    margin-top: 28px;
  }
}
