.product-card-div {
  margin-bottom: 24px;
  box-shadow: 0px 0px 8px #dbdbdb;
  border-radius: 6px;
}
.product-card-div .card {
  border: none !important;
  height: 320px;
  /* height: 390px; */
  background-color: #fdfdfd;
  margin-bottom: 10px;
}
.product-card-div .card-footer {
  background-color: transparent;
  border-top: 0px !important;
  padding-top: 0px !important;
  /* margin-top: 12px; */
}
.product-card-div a {
  color: #0066c0 !important;
}
.like_icon {
  cursor: pointer;
  font-size: 1.5rem;
  position: absolute;
  right: 8%;
  top: 8%;
  background: #f4f3f3;
  border-radius: 50%;
  padding: 3px;
}
.shop-card-image {
  height: 100%;
  min-width: 50%;
  max-width: 100%;
}
.product-title {
  font-size: 15px !important;
  line-height: 1.25 !important;
  margin-top: 4px;
}
.brand-div {
  margin-top: -5px;
}
.brand-name {
  color: #555;
  font-size: 12px !important;
  line-height: 1.25 !important;
}
.ships-to-div {
  margin-top: -1px;
}
.ships-to {
  font-size: 12px !important;
  line-height: 1.25 !important;
}
.product-price-div {
  margin-top: -4px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding-top: 0.4rem;
}
.product-price {
  font-size: 19px;
  color: #111;
}

.reviews-div {
  display: flex;
  margin-top: 1.5%;
}
.total-reviews {
  font-size: 12px !important;
  margin-left: 1%;
}
@media (max-width: 600px) {
  .product-card-div .card {
    margin-bottom: 3%;
  }
  .shop-card-image {
    min-width: 30%;
  }
  .product-price {
    font-size: 17px;
  }
}
@media (max-width: 375px) {
  .product-card-div .card {
    height: 285px;
  }
  .shop-card-image {
    min-width: 40%;
  }
}
