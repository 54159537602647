.iwant-container .container {
  max-width: 80% !important;
  /* margin-top: 4%; */
}
.iwant-container .form-control {
  /* border: 1px solid #ced4da !important; */
  border: 1px solid #dfdfdf !important;
  border-radius: 0.35rem !important;
}
.iwant-container .form-control:focus {
  border-radius: 0.25rem !important;
}
.iwant-container {
  margin-bottom: 3%;
}
.iwant-heading-div {
  margin-top: 4%;
  margin-bottom: 3%;
  text-align: center;
  font-family: "Amazon Ember" !important;
}
.iwant-heading {
  font-weight: 600 !important;
  margin-bottom: 0px !important;
}
.iwant-form-div {
  padding: 5% 8%;
  padding-bottom: 12%;
  box-shadow: 0px 0px 6px #cdcccc;
  /* margin-bottom: 2%; */
  background: #fff !important;
}
.iwant-form-div .form {
  background: #fff !important;
}
.iwant-form-div .form-label {
  font-family: "Amazon Ember" !important;
}
.locations .MuiFormControl-root {
  width: 100%;
  border: 1px solid #dfdfdf !important;
  border-radius: 0.35rem !important;
  padding-left: 8px;
  /* background-color: #fffefe; */
  min-height: 39px;
}
.submit-button {
  font-weight: 500 !important;
  float: right;
  background-color: #64bd6b !important;
  border: #cd9042 !important;
}
.submit-button:hover,
.submit-button:focus {
  box-shadow: 0px 0px 4px #f89518 !important;
}
.submit-button:focus {
  border: 1px solid #f88e07 !important;
  border-color: #f88e07 !important;
}
.iwant-form-div .label {
  color: #707070;
}

.carousel-bg {
  /* background: linear-gradient(
    90deg,
    rgba(29, 92, 173, 1) 0%,
    rgba(45, 110, 194, 1) 100%
  ); */
  background: #2e2f32;
}
.carousel-div {
  height: 100%;
  /* margin-top: 35% !important; */
  padding-top: 55%;
}
.carousel-div .carousel-control-prev,
.carousel-div .carousel-control-next {
  top: 30% !important;
}
.carousel-div .carousel-item {
  /* background-color: black; */
}
.carousel-div img {
  height: 638px;
}
.carousel-div .carousel-caption {
  /* top: 37%; */
  position: relative;
  bottom: 20px;
  z-index: 100;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  width: 60%;
  transform: translate(9%, 28%);
}
@media (max-width: 992px) {
  .iwant-container .container {
    max-width: 90% !important;
  }
  .iwant-form-div {
    margin-top: 4%;
    padding: 6% 8%;
  }
  .carousel-div img {
    height: 400px;
  }
}
@media (max-width: 767px) {
  .iwant-container .container {
    max-width: 100% !important;
  }
}
@media (max-width: 500px) {
  .iwant-form-div {
    padding: 10% 4%;
  }
}
