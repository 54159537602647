.bg-img {
}
.no-results-div {
  margin-top: 3%;
  margin-bottom: 12px;
}
.no-results {
  color: #000000 !important;
  font-size: 18px;
  line-height: 24px;
}
.try-checking {
  color: #0f1111 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
.need-help-div {
  margin-top: 3%;
}
.need-help {
  color: #0f1111;
  font-size: 25px;
  line-height: 32px;
  margin-bottom: 1.5%;
}
.visit-section {
  font-size: 14px !important;
  line-height: 20px !important;
}
