.whatsapp_icon {
  position: fixed;
  bottom: 2.5%;
  right: 1.5%;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  z-index: 1000;
  box-shadow: 0px 0px 14px #6e6e6e;
}
.page-heading {
  font-size: 22px;
  margin-top: 32px;
  /* margin-top: 34px; */
}
