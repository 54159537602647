.top-table .MuiTableCell-root {
  border-bottom: none !important;
}
.left_cells {
  padding-left: 0px !important;
  font-weight: 600 !important;
}
.about {
  font-size: 15px !important;
  line-height: 24px !important;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.description {
  font-size: 14px;
  line-height: 20px;
}
/* .image_magnify_div div div img {
  width: inherit !important;
} */
.addtolist_btn {
  width: 100%;
  background-color: #e9e9ea !important;
}
/* .addtolist_btn :hover {
  background-color: #d8d8d9 !important;
} */
.addtolist_btn:focus {
  border-color: #aeaeae !important;
}
.product_specs {
  margin-top: 20px;
  margin-left: 10px;
  padding: 0px 11px;
}
.product_specs .MuiGrid-spacing-xs-2 {
  margin: 0px !important;
}
.product_policies {
  margin-top: 24px;
  margin-left: 8px;
  padding: 0px;
}
.product_policies:last-child {
  margin-bottom: 12px;
}
.product_policies .about {
  margin-bottom: 5px;
}
.product_policies .description {
  padding-left: 10px;
  padding-right: 14px;
}
.init_quantity {
  margin-top: 23px;
}
.init_quantity.form-group {
  margin-bottom: 16px;
}
.init_quantity .form-control {
  border: 1px solid #d6d4d4 !important;
}
.quantity-input {
  height: 30px;
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
  border: 1px solid #adaaaa !important;
}
.add-btn-icons {
  float: left;
  margin-top: 3px;
  background-color: #3eb986 !important;
  font-size: 120%;
}
.add-cart-btn,
.buy-now-btn {
  width: 100%;
  height: 100%;
  border: 1px solid #4e4e4e !important;
  padding: 4px;
  font-weight: bold !important;
}
.add-cart-btn {
  background-color: #3eb986 !important;
  margin-bottom: 16px;
}
.buy-now-btn {
  background-color: #3eb986 !important;
  margin-bottom: 16px;
}
.policies-mt {
  margin-top: 6px;
}
.description-tables {
  box-shadow: 0px 0px 8px #dbdbdb !important;
  margin-bottom: 8px !important;
  border-radius: 6px !important;
}
.description-tables th {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}
.description-tables tr {
  height: 40px;
}
.description-tables td {
  font-size: 13px;
}
.side-images-div {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #a6a1a1;
  margin-bottom: 6px;
  padding: 4px 5px;
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.side-images {
  max-width: 100%;
  max-height: 100%;
}
@media (min-width: 992px) {
  .left_cells {
    width: 25%;
  }
}
@media (max-width: 992px) {
  .product_detail_div {
    padding-left: 18px !important;
    padding-right: 12px !important;
  }
  /* .image_magnify_div div div img {
    width: 100% !important;
  } */
}
@media (max-width: 599px) {
  .sub_magnify_images div {
    display: flex;
  }
  .sub_magnify_images div img {
    height: 110px !important;
    width: 110px !important;
    margin-right: 7px;
  }
}

.spadaloader-image {
  transform: scale(0.7) !important;
}
