/* .....................................Blogs...................................... */

.blogs-card-main {
  width: auto !important;
  height: auto !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  margin-top: 40px !important;
  box-shadow: 4px 6px 38px rgba(0, 0, 0, 0.12) !important;
  margin-bottom: 20px !important;
}

@media (max-width: 1120px) {
  .blogs-card-main {
    transform: scale(0.9) !important;
    margin: 0px !important;
  }
}

@media (max-width: 1025px) {
  .blogs-card-main {
    transform: scale(0.8) !important;
    margin: 0px !important;
  }
}

@media (max-width: 959px) {
  .blogs-card-main {
    transform: scale(1) !important;
    margin-top: 50px !important;
  }
}

@media (max-width: 775px) {
  .blogs-card-main {
    transform: scale(0.9) !important;
    margin: 0 !important;
  }
}

@media (max-width: 700px) {
  .blogs-card-main {
    transform: scale(0.7) !important;
    margin: 0 !important;
  }
}

@media (max-width: 599px) {
  .blogs-card-main {
    transform: scale(0.9) !important;
    margin: 0 !important;
  }
}

.blogs-card {
  width: 350px !important;
  height: auto !important;
  transition: 0.2s !important;
  transform: scale(0.9) !important;
}

.blogs-img-div {
  width: 100% !important;
  height: 60% !important;
  overflow: hidden;
}
.blogs-image-div:hover {
  transform: scale(1.1);
  transition: 0.5s ease;
}
.blogs-image-div {
  width: 100% !important;
  height: 100% !important;
  transition: 0.5s ease;
}

.blogs-text-main-div {
  padding: 35px 40px !important;
  height: auto !important;
  background-color: #fff !important;
}

.blogs-text-div {
  height: auto !important;
  width: 100% !important;
  display: flex !important;
  justify-content: space-evenly !important;
  display: flex !important;
  padding-bottom: 30px !important;
}

.inspiration-text {
  font-size: 15px !important;
  color: #666666 !important;
  font-weight: 600 !important;
}

.blogs-date-text {
  font-size: 16px !important;
  color: #cccccc !important;
  font-weight: 600 !important;
}

.blogs-trend-text {
  font-size: 27px !important;
  font-weight: bolder !important;
  color: black !important;
  line-height: 28px !important;
  text-transform: capitalize !important;
  padding-bottom: 20px !important;
}

.blogs-text-3 {
  font-size: 16px !important;
  text-transform: capitalize !important;
  color: #999999 !important;
}

.pagination-main-div {
  justify-content: center !important;
  display: flex !important;
  background-color: #f8f8f8 !important;
  height: 70px !important;
  align-items: center !important;
  width: 100% !important;
}

@media (max-width: 425px) {
  .pagination-div {
    transform: scale(0.8) !important;
  }

  .MuiPagination-ul {
    flex-wrap: nowrap !important;
  }
}
