.filter-main-div {
  border-right: 1px solid #dcdbdb;
  /* padding-left: 12px; */
}
.show-refine {
  font-size: 17px !important;
  font-weight: 400 !important;
  color: #767676;
  margin-top: 16px;
  margin-bottom: 14px;
}
.list-div {
  margin-bottom: 14px;
}
.list-heading1 {
  font-size: 12px !important;
  color: #111 !important;
  line-height: 2 !important;
  font-weight: 700 !important;
}
.list-heading {
  font-size: 12px !important;
  color: #111 !important;
  margin-top: 18px;
  margin-bottom: 5px;
  font-weight: 700 !important;
}
.list-item {
  font-size: 12px !important;
  line-height: 1.5 !important;
  color: #111 !important;
  cursor: pointer;
  list-style: none;
}
.list-item:hover {
  font-weight: 600;
}
.active_class {
  font-weight: 600;
}

.list-item-margin {
  /* margin-top: -3px; */
}
.list-div ul {
  padding-inline-start: 0px !important;
}
hr {
  margin: 14px 10px 14px 0px;
  background-color: transparent;
  display: block;
  height: 1px;
  border-width: 0;
  border-top: 1px solid #dcdbdb;
  line-height: 19px;
}

input [type="checkbox"] {
  height: 10px !important;
  width: 10px !important;
  vertical-align: top !important;
  position: relative !important;
  bottom: -3px !important;
  margin-right: 6px !important;
  cursor: pointer;
}
.list-div label {
  margin-bottom: 0px !important;
}
.fill-star {
  color: #f5aa09;
}
.star {
  font-size: 16px;
}
@media (min-width: 1200px) {
  .filter-main-div {
    padding-left: 12px;
  }
}
