.Footer {
  color: white;
  background-color: black !important;
  font-size: 13px;
  margin-top: 20px;
}
.Footer .MuiGrid-container {
  justify-content: center;
}

.Footer__Back-To-Top {
  background-color: #1d1d1d !important;
  text-align: center;
  margin-bottom: 40px;
  transition: 0.2s !important;
}

.Footer__Back-To-Top:focus,
.Footer__Back-To-Top:hover {
  background-color: #3eb986 !important;
  transition: 0.2s !important;
  cursor: pointer;
}

.Footer__Back-To-Top-Text {
  color: white;
  display: block;
  padding: 10px 0;
  line-height: 19px;
  font-size: 40px !important;
}

.Footer__Back-To-Top-Text:hover {
  padding: 0;
  font-size: 50px !important;
}

.Footer__Vertical-Row {
  display: table;
  margin: 0 auto;
  padding-bottom: 40px;
}

.Footer__Vertical-Col-Head {
  font-weight: 700;
  font-family: inherit;
  font-size: 16px;
  margin: 6px 0 14px 0;
}

.Footer__Vertical-Col > ul {
  margin: 0;
  padding: 0;
}

.Footer__Vertical-Col > ul > li {
  list-style-type: none;
  padding-top: 3%;
  padding-bottom: 3%;
}

.Footer__Line {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.Footer__Line-logo {
  width: 100px;
  object-fit: contain;
  margin: 10px !important;
  margin-right: 25px !important;
  transform: scale(1.3) !important;
}

.Footer__Line-message {
  align-items: center;
  display: flex;
  font-size: 120%;
}

.Footer__Line-message > a {
  text-decoration: none;
  color: white !important;
  margin-left: 5px;
  cursor: pointer;
}
.Footer__Line-message > a:hover {
  color: #64bd6b !important;
}
@media (max-width: 959px) {
  .Footer__Vertical-Col {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media (max-width: 600px) {
  .Footer .MuiGrid-container {
    padding-left: 20px;
    justify-content: inherit;
  }
}

.blogs-text {
  color: #fff !important;
}
