body {
  background-color: #f5f5f5 !important;
  box-shadow: 4px 6px 38px rgba(0, 0, 0, 0.12) !important;
}
.card-div .MuiCardContent-root {
  background: #fff !important;
}
.card-div .card-media {
  width: 100%;
  height: 285px;
  object-fit: contain;
  margin-bottom: 0px;
}
.card-div .MuiCardContent-root {
  padding-bottom: 8px !important;
}
.card-buttons .MuiButton-root {
  text-transform: none !important;
  border-bottom: none !important;
}
