.top-heading {
  font-size: 28px !important;
  margin-top: 11px;
  margin-bottom: 6px !important;
}
.top-para {
  font-size: 14px !important;
  /* display: flex; */
}
.category-heading {
  color: #333;
  font-size: 19px !important;
  line-height: 21px;
  text-align: center;
  margin-bottom: 2rem;
  font-family: "Amazon Ember", Arial, sans-serif !important;
}
.category-image {
  max-width: 100%;
}
.category-title {
  text-align: center;
  margin-bottom: 28px;
  font-size: 14px;
  font-family: "Amazon Ember", Arial, sans-serif !important;
}
.result-info-bar {
  margin-top: 1.3rem;
  margin-bottom: 1.3rem;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 0 10px #ddd;
  -webkit-box-shadow: 0 0 10px #ddd;
  -moz-box-shadow: 0 0 10px #ddd;
  display: flex;
  justify-content: space-between;
}
.result-info-bar p {
  font-size: 14px;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  margin-left: 1rem;
}
.result-info-bar form label {
  margin-right: 6px;
}
.result-info-bar form select {
  font-size: 12px;
  padding: 1px;
}
.result-info-bar form {
  font-size: 14px;
  margin-top: 0.6rem;
  margin-right: 1rem;
}
.loaders {
  margin-top: 5%;
  text-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10%;
}
.loaders div {
  width: 65px !important;
  height: 65px !important;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100% !important;
  }
  .shop_columns .col-lg-2
  /* .shop_columns .col-lg-10 */ {
    padding-left: 0px !important;
  }
}
@media (max-width: 767px) {
  .loaders div {
    width: 40px !important;
    height: 40px !important;
  }
}
