.checkoutProduct {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 20px;
}
/* 
.checkoutProduct__price {
} */

.checkoutProduct__info {
  padding-left: 20px;
  align-self: center;
}

.checkoutProduct__info > button {
  background: #3eb986 !important;
  border: 1px solid;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
}
.checkoutProduct__info .MuiFormControl-root {
  width: 100px !important;
}
.checkoutProduct__info .MuiInputLabel-formControl {
  top: -9px !important;
}
.checkoutProduct__info .MuiOutlinedInput-input {
  padding: 8px;
}
.checkoutProduct__image {
  object-fit: contain;
  width: 180px;
  height: 180px;
}

.checkoutProduct__rating {
  display: flex;
}

.checkoutProduct__title:hover {
  color: rgba(0, 0, 0, 0.164) !important;
}

.checkoutProduct__title {
  font-size: 17px;
  font-weight: 800;
  cursor: pointer;
  margin-bottom: 0.3rem !important;
}
::placeholder {
  padding: 0px !important;
}
.price_quantity {
  margin-top: 12px;
  display: flex;
  align-items: center;
}
/* Responsive settings start here */

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (max-width: 991px) {
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (max-width: 767px) {
  .checkoutProduct__rating {
    justify-content: center;
  }

  .checkoutProduct__title {
    /* padding-bottom: 20px; */
  }
  .price_quantity_div {
    display: flex;
  }
  .price_quantity {
    margin-top: 0px;
    margin-left: 24px;
  }
  .checkoutProduct {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-height: 275px;
    width: 100%;
    padding: 10px;
    background: white;
    margin: 10px;
  }
  .checkoutProduct__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: max-content;
    padding-left: 0px;
  }

  .checkoutProduct > img {
    max-height: 100px;
    /* width: 50%; */
    object-fit: contain;
    margin-bottom: 5px;
  }

  .checkoutProduct > p {
    font-size: 14px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
}

@media (max-width: 600px) {
}

/* Responsive settings end here */
