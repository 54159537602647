.header_desktop_nav {
  display: flex;
  flex-direction: column;
}
.become_vendor {
  background-color: black !important;
  display: flex;
  justify-content: flex-end;
  padding-right: 11%;
  align-items: center;
}
.become_vendor a {
  height: 30px;
  padding: 4px 10px;
  text-decoration: underline !important;
  color: #fff !important;
  cursor: pointer;
  /* background-color: #64bd6b;  */
  /* border-color: #64bd6b; */
  /* margin-bottom: 0px; */
  /* color: #d8d8e1; */
  /* background: transparent; */
  /* border: 1px solid transparent; */
}

.all-search-bar {
  width: auto !important;
}
.become_vendor a:hover {
  border: 1px solid white !important;
  text-decoration: none !important;
}
.header__top {
  padding-left: 10px;
  padding-right: 10px;
  height: 60px;
  background-color: black !important;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
}
.header__top,
.header__address,
.header__nav .header__option,
.user_dropdown,
.header__optionBasket {
  border: 1px solid transparent !important;
  /* border-radius: 0.25rem; */
  padding: 1px !important;
}

/* .header__top img:hover, */
.header__address:hover,
.header__nav .header__option:hover,
.user_dropdown:hover,
.header__optionBasket :hover {
  border: 1px solid #fff !important;
  border-radius: 0.15rem;
  cursor: pointer;
  /* margin-left: 10px !important; */
}
.header__bottom span,
.header__bottom-nav div {
  border: 1px solid transparent !important;
  padding: 2px 5px;
}
.header__bottom span:hover,
.header__bottom-nav div:hover {
  border: 1px solid #fff !important;
  padding: 2px 5px;
}
.header__left-nav {
  margin-left: 10px;
  padding: 5px 5px 2px 5px;
  color: white;
  border: 1px solid #666;
}

.header__logo {
  width: 70px;
  /* height: 70px; */
  object-fit: contain;
  margin: 5px 10px 0 10px;
}

.header__search {
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 24px;
  margin: 0px 4px;
}
.header__search select {
  border: 1px solid #eae6e6;
  background-color: rgb(242 240 240);
  font-size: 13px;
  color: #6a6a6a;
  padding: 0px 8px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.header__search option {
  font-size: 14px;
  padding-left: 0px !important;
  width: 300px;
}
.header__searchInput {
  height: 38px;
  padding: 10px;
  border: none;
  width: 100%;
}
.header__searchInput:focus {
  box-shadow: 0px 0px 0px !important;
}
.header__search .input-group-append {
  /* width: 77%; */
  width: 100%;
  padding-right: 8px;
}
.header__search .form-control {
  border-radius: 0px !important;
  background-clip: inherit !important;
}
.header__searchIcon {
  padding: 5px;
  height: 38px !important;
  width: 41px !important;
  background-color: #3eb986 !important;
  color: #000;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.header__nav,
.header__nav span {
  display: flex;
  justify-content: center;
}
.header__nav,
.header__nav a {
  display: flex !important;
  justify-content: center !important;
}

.header__link:first-child {
  width: 130px;
}

.header__link {
  color: #fff !important;
  text-decoration: none;
}
.user_dropdown {
  background: transparent !important;
  /* border: none !important; */
}
.user_dropdown > button {
  display: flex;
  align-items: center;
}
.user_dropdown > button::after {
  margin-left: 6px;
}
.header__option {
  /* height: 5vh; */
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  color: #fff;
}

.header__optionLineOne {
  font-size: 11px;
}

.header__optionLineTwo {
  font-size: 14px;
  font-weight: 700;
}

.header__optionBasket {
  display: flex;
  align-items: center;
  color: white;
  margin-right: 6px;
}

.basket__count {
  font-size: 16px;
  border: 1px solid #fff;
  border-radius: 100px;
  margin: 0 15px 0 -10px;
  padding: 2px 4px;
}

.header__flag {
  width: 24px;
  transform: scale(1.5);
  position: relative;
  display: inline-block;
  height: 18px;
  margin-left: 15px;
  margin-right: 3px;
  background-image: url("https://m.media-amazon.com/images/G/01/AUIClients/InternationalCustomerPreferencesNavAssets-icp_sprite-7285cab5f8342a9a80f19b7ae5b155f77772ffc6._V2_.png");
  background-position: 0 -166px;
}

.header__optionBasket > .MuiSvgIcon-root {
  width: 34px !important;
  height: 34px !important;
}
.header__bottom {
  display: flex;
  max-height: 42px;
  color: white;
  background-color: rgb(29, 29, 29);
  padding: 8px 25px;
  /* padding-top: 10px; */
  /* padding-bottom: 4px; */
}

.header__address {
  display: flex;
  margin: 0px 4px;
}

.header__address-icon {
  vertical-align: middle;
}

.header__address-icon > svg {
  font-size: 0.9rem;
  color: #fff;
  margin-top: 15px;
}
.dropdown button {
  background: transparent !important;
  border: none;
}
.header__bottom-nav {
  display: flex;
  flex: 1;
}

.header__bottom-nav > span {
  cursor: pointer;
  /* margin-left: 10px; */
  margin-left: 2px;
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
}
.header__bottom-nav > a {
  cursor: pointer !important;
  margin-left: 10px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #fff !important;
}

.header__left-nav > .MuiSvgIcon-root {
  font-size: 2em !important;
}
.row1 {
  display: flex;
  align-items: center;
  height: 52px;
  justify-content: space-between;
  padding: 0px 1.6%;
}

.header__top2 {
  padding: 0px 6px;
}
.header__option2 {
  display: flex;
}

.header_mobile_nav {
  display: none;
}
.autoContainer {
  position: absolute;
  border: 1px solid grey;
  background-color: #fff;
  top: 100%;
  /* width: 100%; */
  width: 77%;
  z-index: 1000;
  max-height: 200px;
  overflow-y: scroll;
}
.option {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 12px;
  cursor: pointer;
}
.option:hover,
.option span:hover {
  background: #eeeded;
  font-weight: 600;
  color: #646363;
}
.option:focus {
  border: 1px solid grey !important;
}
.option,
.autoContainer span {
  font-size: 14px;
  text-transform: capitalize;
  color: #5c5b5b;
}

.autoContainer::-webkit-scrollbar {
  width: 8px;
}

.autoContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(194, 193, 193);
  border-radius: 4px;
}

.autoContainer::-webkit-scrollbar-thumb {
  margin: 2px 0px;
  background: rgb(184, 183, 183);
  border-radius: 4px;
}

.autoContainer::-webkit-scrollbar-thumb:hover {
  background: grey;
}
.city-popup > .modal-dialog {
  transform: translate(0, 70%) !important;
}
.city-popup .modal-header {
  background: #f0f2f2;
  padding: 0.7rem 1rem;
}
.city-popup .modal-header > .modal-title {
  font-size: 16px;
  font-weight: 700;
}
.modal-info {
  display: flex;
  border: 1px solid #e8e8e8;
  padding: 12px;
  font-size: 13px;
  margin-bottom: 16px;
}
@media (max-width: 1024px) {
  /* For Ipad Pro */
  .header__search .input-group-append {
    width: 63%;
  }
  .autoContainer {
    width: 63%;
  }
}

@media (max-width: 768px) {
  .header_desktop_nav {
    display: none;
  }
  .header_mobile_nav {
    display: flex;
    flex-direction: column;
    background: black !important;
  }
  /* For Ipad */
  .header__search .input-group-append {
    width: 74%;
  }
  .autoContainer {
    width: 74%;
  }
}
@media (max-width: 600px) {
  .header__bottom-nav {
    max-width: 550px;
    overflow: auto;
  }
}
@media (max-width: 540px) {
  /* For Surface Duo */
  .header__search .input-group-append {
    width: 63%;
  }
  .autoContainer {
    width: 63%;
  }
}

@media (max-width: 414px) {
  /* Iphone 6/7/8 plus */
  .header__search .input-group-append {
    width: 51%;
  }
  .autoContainer {
    width: 51%;
  }
}

@media (max-width: 411px) {
  /* Pixel 2/Xl */
  .header__search .input-group-append {
    width: 50%;
  }
  .autoContainer {
    width: 50%;
  }
}

@media (max-width: 375px) {
  /* Iphone 6/7/8 */
  .header__search .input-group-append {
    width: 45%;
  }
  .autoContainer {
    width: 45%;
  }
}

@media (max-width: 360px) {
  /* Galaxy S5 */
  .header__search .input-group-append {
    width: 43%;
  }
  .autoContainer {
    width: 43%;
  }
}

@media (max-width: 320px) {
  /* Galaxy S5 */
  .header__search .input-group-append {
    width: 36%;
  }
  .autoContainer {
    width: 36%;
  }
}
