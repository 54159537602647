.signup {
  background-color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup__logo {
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: contain;
  width: 180px;
  margin-right: auto;
  margin-left: auto;
}

.signup__container {
  width: 400px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 40px;
}

.signup__container > h1 {
  font-weight: 500;
  margin-bottom: 20px;
}

.signup__container > form > h5 {
  margin-bottom: 5px;
}

.signup__container > form input {
  height: 30px;
  /* margin-bottom: 10px; */
  background-color: white;
  width: 98%;
}
.signup__container .form-group {
  margin-bottom: 0.5rem !important;
}
.signup__container > p {
  margin-top: 15px;
  font-size: 12px;
}

.signup__signInButton {
  background: #63be63 !important;
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
}

.signup__registerButton {
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  border-color: darkgray;
}

.setInputField {
  border-radius: 2px;
  border: 1px solid;
  padding: 15px !important;
}
.display_error {
  color: #fe4444 !important;
  font-size: 12px !important;
}
