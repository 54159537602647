.search-card-div {
  /* margin-left: 15px; */
  /* margin-left: 15px; */
  box-shadow: 0px 0px 8px #dbdbdb;
  border-radius: 6px;
  margin-top: 20px;
}
.search-card-div .card {
  border: none !important;
  height: 275px;
  max-height: 280px;
  flex-direction: row;
  align-items: center;
  /* height: 320px; */
  /* background-color: #fdfdfd; */
  /* margin-bottom: 10px; */
}
.search-card-div .card-body {
  /* padding-bottom: 0px !important; */
}
.column-text {
  align-self: center;
}
.search-card-div .card-footer {
  background-color: transparent;
}
.search-card-div a {
  color: #0066c0 !important;
}
.search-card-image {
  /* height: 100%; */
  /* min-width: 50%; */
  max-width: 100%;
  min-width: 75px;
  max-height: 220px;
  /* max-width: 120px; */
}
.search-title {
  font-size: 15px !important;
  line-height: 1.25 !important;
  margin-top: 4px;
  margin-bottom: 0px;
}
.search-brand-div {
  margin-top: -5px;
}
.search-brand-name {
  color: #555;
  font-size: 12px !important;
  line-height: 1.25 !important;
}
.search-ships-div {
  margin-top: -3px;
}
.search-ships-to {
  font-size: 12px !important;
  line-height: 1.25 !important;
}
.search-price-div {
  margin-top: -1px;
  display: flex;
  justify-content: space-between;
}
.search-price {
  font-size: 16px;
  /* color: #111; */
}

.search-reviews-div {
  display: flex;
  margin-top: 1.5%;
}
.search-total-reviews {
  font-size: 12px !important;
  margin-left: 1%;
}
.delete-div {
  align-self: center;
}
.delete-icon {
  font-size: 19px;
  cursor: pointer;
}
@media (max-width: 992px) {
  .search-card-div .card {
    max-height: 275px;
    margin-bottom: 2%;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
  }
  .search-card-div .card-body {
    padding-bottom: 1.25rem !important;
  }
  .search-card-div {
    margin-left: 0px;
  }
  .search-card-image {
    max-width: 45%;
    max-height: 150px;
  }
  .search-price {
    font-size: 15px;
  }
  .search-ships-to {
    display: none;
  }
  .column-text {
    margin-top: 12px;
  }
}
@media (max-width: 600px) {
  .search-card-div .card {
    max-height: 250px;
  }
  .search-card-image {
    min-width: 36%;
  }
}

@media (max-width: 375px) {
  .search-card-image {
    max-width: 40%;
  }
}
