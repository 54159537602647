.email_div {
  box-shadow: 0px 0px 0px #d1d0d0;
  padding: 4% 8% 5% 8%;

  background-color: #fff;
  border-radius: 10px;
  /* width: 500px !important; */
  transform: translate3d(0%, 10%, 0);
  border: 1px solid lightgray;
}
.logo {
  /* width: 100px;
    object-fit: contain; */
  margin-bottom: 8px;
  font-weight: 500px;
  text-align: center;
}
.forget_heading {
  margin-bottom: 10% !important;
  text-align: center;

  font-weight: 100;
  font-size: 12px !important;
  color: darkgrey;
}
.forget_card {
  border: none !important;
}
.forget_form_control {
  /* height: calc(1.5em + 1.3rem + 2px) !important; */
  border-radius: 0.3rem !important;
}
.form_design {
  position: absolute;
  top: 5px;
  right: 12px;
  color: darkgray;
}

.forgetbtn {
  margin: 1% 1px;
  background: #3eb986 !important;
  border: 1px solid;
  border-color: #a88734 #9c7e31 #846a29 !important;
}
.forgot_div {
  display: flex;
  justify-content: space-between;
}

.post_loader {
  position: absolute;
  left: 45%;
  top: 40%;
  z-index: 100;
}
.post_loader div {
  width: 90px !important;
  height: 90px !important;
}
.load_opacity {
  opacity: 0.7;
}
