.edit_profile_div {
  padding: 22px;
  background-color: #fff;
  width: 55%;
  margin: 0 auto;
  margin-top: 20px;
  box-shadow: 0px 0px 8px #dbdbdb;
  border-radius: 6px;
}

.edit_profile_div .form-group {
  border-bottom: 1px solid #ddd8d8;
  padding-bottom: 1rem;
}
.edit_profile_div label {
  font-weight: 600;
  color: #373636;
}
.edit_buttons {
  width: 55px;
  height: 30px;
  padding: 2px !important;
  background-color: #64bd6b !important;
  border-color: #64bd6b !important;
}
.edit_buttons:focus {
  box-shadow: 0 0 0 2px #c37d25 !important;
}
@media (max-width: 599px) {
  .edit_profile_div {
    width: 97%;
  }
}
