.login {
  background-color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login__logo {
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: contain;
  width: 100px;
  margin-right: auto;
  margin-left: auto;
}

.login__container {
  width: 400px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 40px;
  padding-bottom: 50px;
}

.login__container > h1 {
  font-weight: 500;
  margin-bottom: 20px;
}

.login__container > form > h5 {
  margin-bottom: 5px;
}

.login__container > form > input {
  height: 30px;
  margin-bottom: 10px;
  background-color: white;
  width: 98%;
  border-color: #fff !important;
  border: 1px solid !important;
}

.login__container > p {
  margin-top: 15px;
  font-size: 12px;
}

.login__signInButton {
  background: #63be6b !important;
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
}

.login__registerButton {
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  border-color: darkgray;
}

.setInputField {
  border-radius: 2px;
  /* border: 1px red; */
  padding: 15px !important;
}
.post_loader {
  position: absolute;
  left: 45%;
  top: 40%;
  z-index: 100;
}
.post_loader div {
  width: 90px !important;
  height: 90px !important;
}
.load_opacity {
  opacity: 0.7;
}
.forgot {
  font-size: 14px;
  /* color: #637381; */
  color: #007185 !important;
  text-align: right;
  margin-top: 7px;
}
.forgot:hover {
  color: #2d88ff !important;
}

.btn-primary {
  color: #fff;
  background-color: black !important;
  border-color: #3eb986 !important;
  margin-right: 10px !important;
}

.btn-primary:hover {
  color: #fff;
  border-color: #fff !important;
}
