.dash_sidenav {
  padding: 28px;
  border-right: 1px solid #bebbbb;
  height: 100%;
}
.dash_sidenav h3 {
  font-size: 1rem;
  color: black;
  margin-bottom: 0.3rem !important;
}
.dash_sidenav ul {
  padding-inline-start: 12px !important;
}
.dash_sidenav li {
  list-style: none;
}
.dash_sidenav a {
  color: #3c3d3d !important;
  font-size: 15px;
}
.dash_sidenav a:hover {
  color: black !important;
  /* font-weight: 600; */
}
/* .dash_sidenav a:active {
  color: black !important;
} */
.wish_link:hover {
  color: black !important;
}
