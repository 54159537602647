.search_card_desktop {
  display: block !important;
}
.search_card_mobile {
  display: none !important;
}
.search-heading {
  font-size: 22px;
  margin-top: 32px;
  margin-bottom: 24px;
  /* margin-left: 15px; */
}
@media (max-width: 1199px) {
  .search_filter_column {
    padding-left: 15px;
  }
}
@media (max-width: 992px) {
  .search_card_desktop {
    display: none !important;
  }
  .search_card_mobile {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .search-heading {
    font-size: 18px;
    margin-top: 28px;
  }
}
